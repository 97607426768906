<template>
  <div
    class="Page px-3 !pt-3 pb-5 flex justify-center"
    :class="{
      'h-full': fillHeight,
    }"
  >
    <div
      class="flex-1 container max-sm:!w-full 2xl:!max-w-7xl min-h-[70vh]"
      :class="actualInnerClass"
    >
      <JoszakiHeading v-if="title" class="!mb-2 lg:!mb-6">
        {{ title }}
      </JoszakiHeading>
      <div
        v-if="error"
        class="flex items-center justify-center text-white bg-error text-lg lg:text-2xl w-full !p-6 font-semibold rounded shadow"
      >
        <template v-if="errorMessage">
          {{ errorMessage }}
        </template>
        <i18n v-else path="common.unknownErrorMsg">
          <template #mail>
            <a
              class="text-white hover:text-whitesmoke underline"
              :href="$helpers.support.emailLink"
            >
              {{ $helpers.support.email }}
            </a>
          </template>
          <template #phone>
            <a
              class="text-white hover:text-whitesmoke underline"
              :href="$helpers.support.phoneLink"
            >
              {{ $helpers.support.phone }}
            </a>
          </template>
        </i18n>
      </div>
      <div v-else-if="loading">
        <JoszakiLoading :active="loading" />
      </div>
      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageName: {
      type: String,
      default: null,
    },
    trackingParams: {
      type: Object,
      default: () => {},
    },
    innerClass: {
      type: String,
      default: null,
    },
    innerClassObject: {
      type: Object,
      default: () => {},
    },
    // this will cause the page to fill the remaining height of the screen, footer and stuff will be pushed down and only visible if user scrolls there
    fillHeight: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    actualInnerClass() {
      return {
        [this.innerClass]: !!this.innerClass,
        ...this.innerClassObject,
        "h-full": this.fillHeight,
      };
    },
  },
  beforeDestroy() {
    if (!this.pageName) {
      console.warn("Page name not set, not tracking page leave");
    }
    this.$trackers.pageleave({
      pagename: this.pageName,
      ...this.trackingParams,
    });
  },
  mounted() {
    if (!this.pageName) {
      console.warn("Page name not set, not tracking page view");
    }
    this.$trackers.pageview({
      pagename: this.pageName,
      ...this.trackingParams,
    });
  },
};
</script>
