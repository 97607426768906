import { USER_JWT_TOKEN_COOKIE_NAME } from "./constants";
import { redirectToLogin } from "./helpers";

// This middleware will check if the user has a JWT token
export function authMiddleware(context) {
  const { route, app, $logger } = context;
  const hasToken =
    app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME) || route.query.admin_token;

  // If the user is not authenticated
  if (!hasToken) {
    $logger.debug("AuthMiddleware redirecting", { redirectTo: route.fullPath });
    return redirectToLogin(context);
  }
}
