export const redirectToLogin = ({ route, localePath, redirect }) => {
  const uri = encodeURIComponent(route.fullPath);
  const query = { "callback-url": uri };
  if (route.query.email) {
    query.email = route.query.email;
  }
  const path = localePath({
    name: "login",
    query,
  });
  return redirect(path);
}