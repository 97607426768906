<template>
  <JoszakiCard class="flex flex-col gap-4">
    <div
      v-if="showTitle"
      class="text-2xl font-bold text-primary text-center w-full"
    >
      {{ $t("searchBox.title") }}
    </div>

    <AutocompleteProfession
      ref="prof"
      clearable
      show-label
      :placeholder="$t('searchBox.professionPlaceholder')"
      @select="selectProfession"
    />
    <AutocompleteCity
      clearable
      show-label
      :placeholder="$t('searchBox.cityPlaceholder')"
      @select="selectCity"
    />
    <JoszakiButton
      expanded
      :loading="loading"
      icon-left="search"
      @click="search"
    >
      {{ $t("searchBox.button") }}
    </JoszakiButton>
  </JoszakiCard>
</template>

<script>
import AutocompleteProfession from "@/components/_refactored/autocomplete/AutocompleteProfession.vue";
import AutocompleteCity from "@/components/_refactored/autocomplete/AutocompleteCity.vue";

export default {
  components: {
    AutocompleteCity,
    AutocompleteProfession,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      city: null,
      profession: null,
      loading: false,
    };
  },
  methods: {
    selectCity(city) {
      this.city = city;
    },
    selectProfession(profession) {
      this.profession = profession;
    },
    search() {
      try {
        this.loading = true;
        if (this.city && this.profession) {
          this.$trackers.trackClick("SearchBoxButton");

          if (this.city.parentCity) {
            this.$router.push(
              this.localePath({
                name: "professionalsParentCity",
                params: {
                  profession: this.profession.seoName,
                  parentCity: this.city.parentCity.seoName,
                  city: this.city.seoName,
                },
              })
            );
            return;
          }

          this.$router.push(
            this.localePath({
              name: "professionals",
              params: {
                profession: this.profession.seoName,
                city: this.city.seoName,
              },
            })
          );
        } else if (!this.city) {
          this.$joszaki.toast({
            message: this.$t("searchBox.inputCityError"),
            type: "error",
          });
        } else if (!this.profession) {
          this.$joszaki.toast({
            message: this.$t("searchBox.inputProfessionError"),
            type: "error",
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
