import { ref, computed } from "@nuxtjs/composition-api";
import { useTextSearch } from "./useTextSearch";
import useCapital from "~/composables/useCapital";
import { useLazyStore } from "~/stores/lazy";

export function useCitySearch(filterCapital = false) {
  const { isCapital } = useCapital();
  const cityFilter = ref("");

  const lazyStore = useLazyStore();

  const cities = computed(() => {
    if (!lazyStore.citiesLoaded) {
      return [];
    }

    return filterCapital
      ? lazyStore.cities.filter((c) => !isCapital(c))
      : lazyStore.cities;
  });

  const { results: filteredCities } = useTextSearch(cityFilter, cities, {
    mapperFn: (city) => city.name.split("(")[0].trim(),
  });

  return {
    cityFilter,
    cities,
    filteredCities,
    loadCities: lazyStore.loadCities,
  };
}
