import { useContext } from "@nuxtjs/composition-api";

export function useSendPasswordRecovery() {
  const { $mutate, i18n } = useContext();

  return async function sendPasswordRecovery(payload) {
    const SEND_PASSWORD_RECOVERY = await import(
      "../mutations/sendPasswordRecoveryMutation.graphql"
    );
    return $mutate(
      SEND_PASSWORD_RECOVERY,
      {
        email: payload.email,
      },
      {
        showErrorToast: true,
        showSuccessToast: true,
        errorMessage: i18n.t("login.passwordRecovery.error"),
        successMessage: i18n.t("login.passwordRecovery.success"),
      }
    );
  };
}
