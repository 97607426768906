<template>
  <JoszakiPage>
    <div class="flex flex-col items-center gap-4">
      <p class="text-xl lg:text-5xl font-bold">
        {{ $t("error.page.title") }}
      </p>
      <div
        :style="{ backgroundImage: `url(${backgroundUrl})` }"
        class="w-11 lg:w-32 aspect-square bg-cover"
      />
      <p class="text-xl lg:text-3xl">
        {{ error.statusCode }} - {{ error.message }}
      </p>
      <p v-if="error.detailedMessage" class="text-lg lg:text-xl">
        {{ error.detailedMessage }}
      </p>
      <p>
        <i18n path="error.page.findProfessionalOrRequestTender">
          <template #strongText>
            <span class="font-bold">{{ $t("error.page.strongText") }}</span>
          </template>
        </i18n>
      </p>
      <OfferOrSearch
        class="w-full"
        @requestTender="requestTender({ source: 'personNotFound' })"
      />
    </div>
  </JoszakiPage>
</template>

<script>
import { defineComponent, useRoute, useContext } from "@nuxtjs/composition-api";
import OfferOrSearch from "~/components/mainpage/OfferOrSearch.vue";

export default defineComponent({
  name: "ErrorLayout",
  components: { OfferOrSearch },
  layout: "DefaultLayout",
  props: {
    error: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup({ error }) {
    const route = useRoute();
    const { redirect, localePath, $joszaki } = useContext();
    if (error.statusCode === 401) {
      $joszaki.toast({
        message: error.detailedMessage
          ? `${error.message}: ${error.detailedMessage}`
          : error.message,
        type: "error",
      });
      if (
        route.value.path.startsWith(localePath({ name: "registrationPage" }))
      ) {
        redirect(401, localePath({ name: "login" }));
      } else {
        redirect(401, localePath({ name: "login" }), {
          redirectTo: encodeURIComponent(route.value.fullPath),
        });
      }
    }

    return {
      backgroundUrl: require("@/assets/joszaki-error.png"),
    };
  },
});
</script>
