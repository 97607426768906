var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dark:text-white fixed top-0 left-0 right-0 w-full lg:p-4 md:inset-0 h-full justify-center items-center bg-white-darker/90 overscroll-contain isolate z-[1000]",class:{
    flex: _vm.open,
    hidden: !_vm.open,
  },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.close(true)}}},[_c('dialog',{staticClass:"w-full bg-white rounded-lg shadow-md flex flex-col isolate",class:{
      'max-w-xl max-h-[60%]': _vm.size === 'sm',
      'max-w-2xl max-h-[75%]': _vm.size === 'md',
      'max-w-screen-lg max-h-[100%]': _vm.size === 'lg',
    },attrs:{"arial-modal":"true"}},[_c('div',{staticClass:"flex-none flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600"},[_vm._t("header",function(){return [_c('h3',{staticClass:"text-xl font-semibold text-gray-900"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]}),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.close(false)}}},[_c('IconComponent',{staticClass:"text-gray-600 dark:text-white m-1 cursor-pointer",attrs:{"icon":"times"}})],1)],2),_c('div',{staticClass:"flex-initial min-h-0 p-4 space-y-6 overflow-auto"},[_vm._t("body",function(){return [_vm._v(" Joszaki body ")]})],2),(!_vm.hideFooter)?[_c('div',{staticClass:"flex-none flex items-center space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600 p-4"},[_vm._t("footer",function(){return [_c('JoszakiButton',{staticClass:"flex-1",attrs:{"type":"error"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('JoszakiButton',{staticClass:"flex-1",attrs:{"type":"success","loading":_vm.loading,"disabled":_vm.disableConfirm},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.confirmButtonLabel ?? _vm.$t("common.accept"))+" ")])]})],2)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }