import jwt from "jsonwebtoken";
import { USER_JWT_TOKEN_COOKIE_NAME } from "./constants";
import { useCookieManager } from "./composables/useCookieManager";
import { redirectToLogin } from "./helpers";

// This middleware will check if the user has a JWT token with admin rights
//   - If the user is not authenticated, it will redirect to the login page
//   - If the user is not admin, it will redirect to the forbidden page
//   - If the token is invalid, it will delete the token and redirect to the login page
// Note: this does not validate the token, the server will do that
// so the user will be redirected to the login page/forbidden page
// if the token is invalid according to the server
export function adminMiddleware(context) {
  const {
    app,
    route,
    error,
    $logger,
    i18n,
  } = context;

  const jwtToken =
    app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME) || route.query.admin_token;

  if (!jwtToken) {
    $logger.debug("AdminMiddleware redirecting", { redirectTo: route.fullPath });
    return redirectToLogin(context);
  }

  try {
    const decodedToken = jwt.decode(jwtToken);

    if (decodedToken.isAdmin) {
      return;
    }

    $logger.debug("AdminMiddleware redirecting to Forbidden page");

    error({
      statusCode: 403,
      message: i18n.t("error.codes.FORBIDDEN"),
    });
  } catch (e) {
    $logger.error("AdminMiddleware could not decode token:", e);
    const cookieManager = useCookieManager(context);
    cookieManager.clearTokenCookieForAll();
    return redirectToLogin(context);
  }
}
