import { useContext } from "@nuxtjs/composition-api";

export function useMiniMarketTeamActions() {
  const { $mutate, $query } = useContext();

  async function saveMiniMarketTeam(submitData) {
    try {
      if (submitData.id !== null) {
        const UPDATE_MINI_MARKET_TEAM = await import(
          "./updateMiniMarketTeam.graphql"
        );
        return await $mutate(UPDATE_MINI_MARKET_TEAM, submitData);
      } else {
        const CREATE_MINI_MARKET_TEAM = await import(
          "./createMiniMarketTeam.graphql"
        );
        return await $mutate(CREATE_MINI_MARKET_TEAM, submitData);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function deleteMiniMarketTeamById(id) {
    try {
      const DELETE_MINI_MARKET_TEAM = await import(
        "./deleteMiniMarketTeamById.graphql"
      );
      await $mutate(DELETE_MINI_MARKET_TEAM, { id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getMiniMarketTeamById(id) {
    try {
      const GET_MINI_MARKET_TEAM = await import(
        "./getMiniMarketTeamById.graphql"
      );
      const { miniMarketTeamById } = await $query(GET_MINI_MARKET_TEAM, { id });
      return miniMarketTeamById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getMiniMarketTeams(params) {
    try {
      const GET_MINI_MARKET_TEAMS = await import(
        "./miniMarketTeamsDataTable.graphql"
      );
      const { miniMarketTeamsDataTable } = await $query(
        GET_MINI_MARKET_TEAMS,
        params
      );
      return miniMarketTeamsDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    saveMiniMarketTeam,
    deleteMiniMarketTeamById,
    getMiniMarketTeamById,
    getMiniMarketTeams,
  };
}
