<template>
  <div
    class="QuickOffer w-full grid"
    :class="{
      'bg-white rounded-lg shadow-md': version === 'white',
      'bg-typo-primary/5 rounded-md shadow-none': version === 'grey',
      'bg-primary rounded-md shadow-none': version === 'blue',
      'justify-items-center': textInput,
      'gap-3 p-5': size === 'lg',
      'gap-1 p-2': size === 'sm',
    }"
  >
    <span
      class="font-bold text-2xl"
      :class="{
        'text-primary': version === 'white',
        'text-typo-primary': version === 'grey',
        'text-white': version === 'blue',
      }"
    >
      {{ title }}
    </span>
    <span
      :class="{
        'text-xl font-bold text-primary': version === 'white',
        'text-sm font-semibold text-typo-primary': version === 'grey',
        'text-sm font-bold text-white': version === 'blue',
      }"
    >
      {{ subtitle }}
    </span>
    <TenderSteps
      v-if="!hideSteps"
      class="max-w-lg max-h-20 mb-5"
      :is-white="version === 'blue'"
    />
    <div
      v-if="textInput"
      class="flex flex-col w-full text-center"
      :class="{
        'text-typo-primary': version === 'grey',
        'text-white': version === 'blue',
        'my-4': size === 'lg',
        'my-1': size === 'sm',
      }"
    >
      <p
        class="text-base font-bold mb-1"
        :class="{ 'text-white': version === 'blue' }"
      >
        {{ $t("quickOffer.inputLabel") }}
      </p>
      <textarea
        v-model="tenderText"
        class="w-full h-20 min-h-[20px] shadow-inner border border-solid border-gray-400 p-3 focus-visible:border-primary focus-visible:outline-none placeholder:text-gray-400 rounded-md"
        :class="{
          'outline-primary': version === 'white',
          'bg-white/20 border border-solid border-black/20 outline-none shadow-none font-semibold':
            version === 'grey',
          'outline-white': version === 'blue',
        }"
        :placeholder="textareaPlaceholder"
        @focus="$trackers.trackClick('QuickOfferInput')"
      />
    </div>
    <JoszakiButton
      class="w-full"
      :inverted="version === 'blue'"
      @click="requestTender"
    >
      {{ textOfButton }}
    </JoszakiButton>
  </div>
</template>

<script>
import TenderSteps from "@/components/_refactored/img/TenderSteps.vue";
import JoszakiButton from "@/components/_refactored/common/JoszakiButton.vue";

export default {
  components: {
    TenderSteps,
    JoszakiButton,
  },
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t("quickOffer.title");
      },
    },
    subtitle: {
      type: String,
      default: function () {
        return this.$t("quickOffer.subtitle");
      },
    },
    isBlog: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    version: {
      type: String,
      default: "white",
      validator: (val) => ["white", "grey", "blue"].includes(val),
    },
    size: {
      type: String,
      default: "lg",
      validator: (val) => ["sm", "lg"].includes(val),
    },
    placeholder: {
      type: String,
      default: null,
    },
    hideSteps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tenderText: "",
    };
  },
  computed: {
    profession() {
      return this.$store.getters["listPage/profession"];
    },
    textareaPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }

      return this.$t("quickOffer.placeholder");
    },
    textOfButton() {
      if (this.buttonText) {
        return this.buttonText;
      }
      if (this.profession) {
        return this.$t("quickOffer.button", {
          professionObjective: this.profession.objective,
        });
      } else {
        return this.$t("quickOffer.button_general");
      }
    },
  },
  methods: {
    requestTender() {
      this.$track.analyticsEvent({
        category: "gyorsitott",
        action: "kattintas-ajanlatkeresre",
      });

      this.$track.analyticsEvent({
        category: "quick-tender",
        action: "click",
      });

      this.$trackers.trackClick("QuickOfferButton");

      this.$emit("requestTender", this.tenderText);
    },
  },
};
</script>
